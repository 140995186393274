<template>
  <div>
    <div>
      <base-button width="auto" @click="$emit('openAllShare')" class="tw-cursor-pointer" outlined button-text="Share Jobs">
        <template #left-icon>
          <vue-feather class="tw-cursor-pointer mr-2" style="color: #008EEF" size="20" type="share-2"/>
        </template>
      </base-button>
<!--      <v-btn color="white" width="101px" icon @click="$emit('openAllShare')" height="48px" outlined-->
<!--             class="tw-capitalize w-normal-case	 font-weight-bold tw-rounded-lg btnClass">-->
<!--        <span class="share blue&#45;&#45;text"> Share Jobs</span>-->
<!--      </v-btn>-->
    </div>
    <v-dialog persistent v-model="dialog" width="776">
      <template v-slot:default>
        <v-card class="share-modal">
          <div class="tw-flex lg:tw-px-5 tw-justify-between">
            <div class="share-header tw-my-5">Share all job opening</div>
            <XIcon @click="closeModal" class="tw-mt-3 tw-cursor-pointer"/>
          </div>
          <div class="lg:tw-ml-5">
            <p class="share-text">Copy the link generated and send it to people you want to see it.</p>
<!--            <div class="d-flex tw-mb-5">-->
<!--              <v-avatar class="tw-mr-3" color="#ECF4F7" size="100" tile>-->
<!--                <img :src="logo" alt=""/>-->
<!--              </v-avatar>-->
<!--              <div>-->
<!--                <p class="lg:ml-sm-3 mt-sm-3 mb-0 job-title">{{ jobTitle }}</p>-->
<!--                <p class="lg:ml-sm-3 mb-1 job-role">{{ company }} . {{ jobType }}</p>-->
<!--                <div class="tw-flex">-->
<!--                  <MapPinIcon class="mt-1 lg:ml-2" size="14" style="color: #9CABB5;"/>-->
<!--                  <p class="job-type tw-ml-1 pb-1">{{ location ? 'Remote' : 'On-site' }}</p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
          </div>
          <div>
            <div class="md:tw-ml-5 copy-div tw-flex tw-justify-between md:tw-w-620">
              <span :class="$vuetify.breakpoint.smAndDown?'truncate':''" class="link">{{ baseUrl }}</span>
              <div v-if="isCopy">
                <span class="copied">Copied</span>
              </div>
              <div v-else class="tw-flex tw-cursor-pointer" @click="copy()">
                <vue-feather class="" type="copy"></vue-feather>
                <h2 class="tw-ml-4 tw-hidden md:tw-block">Copy link</h2>
              </div>
            </div>
          </div>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { XIcon} from "vue-feather-icons"
import getSiteName from "@/services/utils";
import BaseButton from "@/components/UIComponents/BaseButton";

export default {
  name: "ShareAllJobs",
  components: {BaseButton, XIcon},
  data() {
    return {
      baseUrl: 'http://localhost:8081/search',
      isCopy: false
    }
  },
  props: {
    dialog: [Boolean]
  },
  mounted() {
    if (process.env.NODE_ENV === "development") {
      this.baseUrl = `http://localhost:8081/search?site_name=${getSiteName()}`
    } else {
      this.baseUrl = `${process.env.VUE_APP_PROD_TALENT_FRONT_URL}/search?site_name=${getSiteName()}`
    }
  },

  methods: {
    async copy() {
      if (process.env.NODE_ENV === "development") {
        this.baseUrl = `http://localhost:8081/search?site_name=${getSiteName()}`
      } else {
        this.baseUrl = `${process.env.VUE_APP_PROD_TALENT_FRONT_URL}/search?site_name=${getSiteName()}`
      }
      await navigator.clipboard.writeText(this.baseUrl);
      this.isCopy = true
    },
    closeModal() {
      this.isCopy = false
      this.$emit('close')
    }
  },
}
</script>

<style scoped>
.share-modal {
  background: #FFFFFF;
  border-radius: 21px;
  padding: 20px 20px 45px;
}

.share-header {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
}

.share-text {
  font-family: "DM Sans", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  /*text-align: center;*/
  color: #9CABB5;
}
.copy-div {
  background: #FFFFFF;
  border: 1px solid #D0DCE4;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 17px 12px 15px 15px;
}

.link {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #475661;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 300px !important;

}

.copied {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #008EEF;
}

.truncate {
  width: 185px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.share{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;

  color: #475661;

}
</style>
