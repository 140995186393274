<template>
  <div>
    <Loader v-if="loading" :loading="loading"/>
    <div style="margin-top: 40px" v-else>
      <div class="tw-w-11/12 tw-mx-auto lg:tw-mx-0" >
        <div class="d-flex flex-wrap justify-space-between align-center ">
          <h2 class="text-capitalize tw-text-enumGrey2 tw-text-2xl tw-my-6 md:tw-my-0 tw-font-semibold">Jobs</h2>
          <div class="tw-flex align-center tw-py-8">
            <div>
              <share-all-jobs @close="allDialog=false" :dialog="allDialog" @openAllShare="allDialog=true"/>
            </div>
            <base-button id="postJob" :depressed="true" :width="'160px'" class="tw-ml-5  tw-hidden md:tw-flex" @click="routeTo" button-text="Post a Job"/>
          </div>
        </div>
        <div>
          <v-row>
            <v-col  md="9" sm="12"  class=" tw-order-2 md:tw-order-none">
              <DashboardHorizontalTabSliders @tab:click="checkData" :tabs="tabs" id="tabs"/>
            </v-col>
            <v-col sm="12" cols="12" md="3" class=" tw-order-1 md:tw-order-none ">
              <div >
                <v-text-field class="" v-model="search"  hide-details label="Search" solo/>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="card-table">
          <v-data-table
              :headers="headers"
              :search="search"
              hide-default-header
              :items-per-page="itemsPerPage"
              :page.sync="page"
              :items="tableData"
              :hide-default-footer="tableData.length<=0"
              :no-data-text="noData"
              @page-count="pageCount = $event"
              height="52vh"
              class="mt-4 mx-1 table"
          >
            <template v-slot:[`item`]="{ item }">
              <div @click="gotoRoute(item.id,item.status)" class="lg:tw-mr-4 d-flex tw-flex-col md:tw-flex-row my-3 pa-5 jobCard justify-space-between mb-5" >
                <div>
                  <div class="d-flex">
                    <h3 class="jobTitle tw-text-enumGrey2">{{ item.title }}</h3>
                    <div class="py-1 px-2 ml-4 status  sm:tw-block tw-hidden">
                      <h4 :style="{color:changeColor(item.status)}" class="jobStatus tw-text-enumGrey2">{{ item.status }}</h4>
                    </div>
                    <div class=" sm:tw-hidden tw-flex tw-justify-end">
                      <v-menu bottom offset-y left>
                        <template v-slot:activator="{ on, attrs }">
                          <td class="d-flex justify-end">
                            <v-btn v-bind="attrs" v-on="on" icon>
                              <vue-feather type="more-vertical"/>
                            </v-btn>
                          </td>
                        </template>
                        <v-list class="tw-cursor-pointer">
                          <v-list-item
                              v-for="(title, i) in getCurrentAction(item.status)"
                              :key="i"
                          >
                            <v-list-item-title class="list tw-cursor-pointer" @click="actionToPerform(title,item)">{{ title }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </div>
                  <div class="d-flex align-center mt-1 ">
<!--                    <h4 class="jobLevelAndLocation tw-text-enumGrey2">{{ item.experience_level }}</h4>-->
<!--                    <v-avatar class="mx-2" size="7" color="#8F47F4"></v-avatar>-->
                    <h4 class="jobLevelAndLocation tw-text-enumGrey2 tw-mr-2" v-if="item.state">{{item.state}}, {{ item.country }} </h4>
<!--                    <h4 class="jobLevelAndLocation tw-text-enumGrey2" v-if="item.is_remote"> (Remote)</h4>-->
                    <span class="bullet jobLevelAndLocations mr-2 tw-text-enumGrey2">&bull;</span>
                    <div class="tw-flex align-center ">
                      <span class="jobLevelAndLocations tw-text-enumGrey2  tw-hidden lg:tw-block tw-pr-3">{{item.metric.application
                        }} Applicants</span>
                      <span class="bullet jobLevelAndLocations mr-2 tw-text-enumGrey2">&bull;</span>
                      <span class="jobLevelAndLocations tw-text-enumGrey2 tw-hidden lg:tw-block">{{
                          item.metric.qualified
                        }} Qualified</span>
                    </div>
                  </div>

                </div>
                <div class="sm:tw-block tw-hidden d-flex">
                  <div>
                    <span class="jobCreated tw-text-enumGrey3">{{ processedDate(item.created_at) }}</span>
                  </div>
                  <div class="sm:tw-block tw-hidden tw-justify-end mt-n1" >
                    <v-menu bottom offset-y left>
                      <template v-slot:activator="{ on, attrs }">
                        <td class="d-flex justify-end">
                          <v-btn v-bind="attrs" v-on="on" icon>
                            <vue-feather type="more-vertical"/>
                          </v-btn>
                        </td>
                      </template>
                      <v-list class="tw-cursor-pointer">
                        <v-list-item
                            v-for="(title, i) in getCurrentAction(item.status)"
                            :key="i"
                        >
                          <v-list-item-title class="list tw-cursor-pointer" @click="actionToPerform(title,item)">{{ title }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </div>

                <div class=" sm:tw-hidden mt-5 tw-flex justify-space-between align-center">
                  <div class="">
                    <h2 class="jobCreated tw-text-enumGrey3">{{ processedDate(item.created_at) }}</h2>
                  </div>
                  <div class="py-1 px-2 ml-4 status ">
                    <h4 class=" jobStatus tw-text-enumGrey2 ">{{ item.status }}</h4>
                  </div>
                </div>
              </div>
            </template>
          </v-data-table>

        </div>
        <SplashConfirmModal
            @close="closeModal"
            :dialog="dialog"
            :title="'Delete Job'"
            :sub-title="'Are you sure you want to delete this job'"
            @function="deleteJob"
            :loading="deleteLoading"
        />
        <close-job-modal :dialog="closeJob" @close="handleCloseJobModal" :job-title="closeJobDetail.title" :job-id="closeJobDetail.id" />
        <ShareJobsModal :dialog="shareModal" @close="closeShare" :job-title="jobDetails.title" :id="jobDetails.id" :logo="jobDetails.company_logo || noImage"
                        :job-type="jobDetails.type" :location="jobDetails.is_remote" :company="jobDetails.company_name"/>
      </div>
      <div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import DashboardHorizontalTabSliders from "@/components/dashboard/reuseables/DashboardHorizontalTabSliders";
import BaseButton from "@/components/UIComponents/BaseButton";
import Loader from "@/components/UIComponents/reusablesIcon/Loader";
import SplashConfirmModal from "@/components/UIComponents/SplashConfirmModal";
import ShareJobsModal from "@/components/jobPosting/reuseables/ShareJobsModal";
import CloseJobModal from "@/components/viewAdminJobs/CloseJobModal";
import ShareAllJobs from "@/components/jobPosting/reuseables/ShareAllJobs";

export default {
name: "AllJobs",
  components: {
    ShareAllJobs,
    CloseJobModal, ShareJobsModal, SplashConfirmModal, Loader, BaseButton, DashboardHorizontalTabSliders},
  data(){
    return{
      loading: false,
      search: "",
      dialog: false,
      allDialog: false,
      jobId: null,
      closeJob: false,
      jobDetails:{},
      closeJobDetail:{},
      tableData: [],
      noImage: 'https://images.unsplash.com/photo-1632169505206-9506f48761e6?ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzfHx8ZW58MHx8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
      shareModal:false,
      noData: 'You don’t have any job posting ',
      deleteLoading: false,
      filteredResult : [],
      liveActions:['Close', 'Share'],
      draftActions:['Edit', 'Delete'],
      closedActions:['Delete'],
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,

      headers: [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "title"
        },
        {text: " ", value: "level"},
        {text: " ", value: "status"},
        {text: "", value: "state"},
        {text: "", value: "city"},

      ],
      tabs: [
        {
          name: 'All',
        },
        {
          name: 'Draft',
        },
        {
          name: 'Live',
        },
        {
          name: 'Closed',

        }
      ],
    }
  },
  computed:{
    ...mapGetters("adminJobs",["allAdminJobs", "allDraftJobs", "allLiveJobs", "allClosedJobs"]),
    ...mapGetters('editCompany', ['getCompanyProfile']),
  },
  methods: {
    getCurrentAction(status){
      if(!status) return
      let data;
      const actions = {
        'closed': () => {
          return this.closedActions
        },
        'live': () => {
          return this.liveActions
        },
        'draft': () => {
          return this.draftActions
        }
      }
      data = actions[status.toLowerCase()]?.()
      return data

    },
    actionToPerform(title, item){
      console.log(item)
      const actions = {
        'close': () => {
          this.closeJobDetail = {...item}
          this.closeJob = true
        },
        'delete': () => {
          this.jobId = item.id
          this.dialog = true
          console.log(item)
        },
        'share': () => {
          this.shareModal = true
          console.log(this.getCompanyProfile,item)
          this.jobDetails = {...this.getCompanyProfile,...item}
        },
        'edit': () => {
          this.$router.push({name :"Job-stepOne", query:{jobId:item.id}})
        }
      }
      actions[title.toLowerCase()]?.()
    },
    closeShare(){
      this.shareModal = false
    },
    changeColor(item) {
      if(item === 'Live') {
        return '#01A45F'
      }else if(item === 'Draft') {
        return '#4F4F4F'
      }else {
        return '#BA0000'
      }
    },
    closeModal() {
      this.dialog = !this.dialog
    },
    async handleCloseJobModal(){
      this.closeJob = false
    },
    processedDate(createdDate) {
      if (!createdDate) return;
      let day;
      let date, now
      day = createdDate.split("T")[0]
      let d1 = date = new Date(day);
      let d2 = now = new Date();
      if (d2.getTime() < d1.getTime()) {
        d1 = now;
        d2 = date;
      }
      let yd = d1.getYear();
      let yn = d2.getYear();
      let years = yn - yd;
      let md = d1.getMonth();
      let mn = d2.getMonth();
      let months = mn - md;
      if (months < 0) {
        years--;
        months = 12 - md + mn;
      }
      let dd = d1.getDate();
      let dn = d2.getDate();
      let days = dn - dd;
      if (days < 0) {
        months--;
        // figure out how many days there are in the last month
        d2.setMonth(mn, 0);
        days = d2.getDate() - dd + dn;
      }
      let weeks = Math.floor(days / 7);
      days = days % 7;
      if (years > 0) return years + 'year' +  (years > 1 ? "s" : "") + " " + "ago"
      if (months > 0) return months + ' month' +  (months > 1 ? "s" : "") + " " + "ago"
      if (weeks > 0) return weeks + ' week' +  (weeks > 1 ? "s" : "") + " " + "ago"
      if(days > 0) return  days + ' day' +  (days > 1 ? "s" : "")  + " " + "ago" ;
      return "Created Today";

    },
    gotoRoute(item,status) {
      if(this.getCompanyProfile.verified === false){
        this.$displaySnackbar({message: 'Your organization has not been verified. ' +
              ' An enum admin would reach out to you via phone to verify your organization', success: false})
        return
      }
      if (status === 'Draft'){
        this.$router.push({name :"Job-stepOne",query:{jobId:item}})
      }
      else {
        this.$router.push({path:"/dashboard/jobs/candidates/" + item})
      }



    },
    routeTo() {
      sessionStorage.removeItem('returnedSelectedDetails')
      this.$router.push({name: 'Job-stepOne'})
    },
    checkData(item) {
      if(item === 'All') {
        this.tableData = this.allAdminJobs
      }
      else if(item === 'Draft') {
        this.tableData = this.allAdminJobs.filter(item => item.status === 'Draft')
      }
      else if(item === 'Live') {
        this.tableData = this.allAdminJobs.filter(item => item.status === 'Live')

      }
      else if (item === 'Closed') {
        this.tableData = this.allAdminJobs.filter(item => item.status === 'Closed')
      }
    },
    async getProfile(){
      await this.$store.dispatch('editCompany/getCompanyProfile', this.$siteName())
    },
    async allJobs() {
      const siteName = this.$siteName()
      await this.$store.dispatch("adminJobs/getAllAdminJobs", siteName).then(
          res => {
            console.log(res)
          }).catch(
          err => {
            console.log(err)
          }
      ).finally(
          () => this.loading = false
      )
    },
    deleteJob(){
      this.deleteLoading = true
      this.$store.dispatch('adminJobs/deleteSingleJob', this.jobId).then(
          () => {
            this.checkData('All');
            this.$displaySnackbar({
              message: 'Job template deleted successfully',
              success: true
            })
            this.deleteLoading = false
            this.dialog = false
          }
      )

    }
  },
  async created() {
    this.loading = true;
    await this.allJobs();
    this.loading = false
    await this.getProfile()
    await this.checkData('All');
  },
  mounted() {
    this.checkData('All');
  }
}
</script>

<style scoped>
.jobTitle{
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 140%;
}
.jobLevelAndLocations{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #9CABB5;
}
.jobLevelAndLocation{
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  letter-spacing: 0.01em;
}
h4,h3,p{
  text-transform: capitalize;
  font-family: DM Sans;
}
h2{
  font-family: DM Sans;
}
.status{
  border: 1px solid #eaeaea;
  border-radius: 8px;
}
.jobCard{
  box-shadow: 0px 8px 16px #F0F9FF ;
  cursor: pointer;
}
.jobStatus{
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.jobCreated{
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  letter-spacing: 0.01em;
}
.table >>> ::-webkit-scrollbar {
  width: 3px;
}
.table >>> ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

.table >>> ::-webkit-scrollbar-thumb {
  background: #808080;
  border-radius: 5px;
}
p{
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  text-transform: capitalize;
}
.v-tab{
  min-width: 0;
  padding: 0;
}
</style>
